import _core from "./core";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
;

(function (root, factory) {
  // CommonJS
  exports = exports = factory(_core);
})(exports, function (CryptoJS) {
  (function () {
    // Check if typed arrays are supported
    if (typeof ArrayBuffer != "function") {
      return;
    } // Shortcuts


    var C = CryptoJS;
    var C_lib = C.lib;
    var WordArray = C_lib.WordArray; // Reference original init

    var superInit = WordArray.init; // Augment WordArray.init to handle typed arrays

    var subInit = WordArray.init = function (typedArray) {
      // Convert buffers to uint8
      if (typedArray instanceof ArrayBuffer) {
        typedArray = new Uint8Array(typedArray);
      } // Convert other array views to uint8


      if (typedArray instanceof Int8Array || typeof Uint8ClampedArray !== "undefined" && typedArray instanceof Uint8ClampedArray || typedArray instanceof Int16Array || typedArray instanceof Uint16Array || typedArray instanceof Int32Array || typedArray instanceof Uint32Array || typedArray instanceof Float32Array || typedArray instanceof Float64Array) {
        typedArray = new Uint8Array(typedArray.buffer, typedArray.byteOffset, typedArray.byteLength);
      } // Handle Uint8Array


      if (typedArray instanceof Uint8Array) {
        // Shortcut
        var typedArrayByteLength = typedArray.byteLength; // Extract bytes

        var words = [];

        for (var i = 0; i < typedArrayByteLength; i++) {
          words[i >>> 2] |= typedArray[i] << 24 - i % 4 * 8;
        } // Initialize this word array


        superInit.call(this || _global, words, typedArrayByteLength);
      } else {
        // Else call normal init
        superInit.apply(this || _global, arguments);
      }
    };

    subInit.prototype = WordArray;
  })();

  return CryptoJS.lib.WordArray;
});

export default exports;